Copy code
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  font-size: calc(20px + 2vmin);
  color: white;
}

.header-home-background {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 160px; 
  background: linear-gradient(to top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.9));
  z-index: 1;
}

.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 20px; /* add some padding for spacing */
  box-sizing: border-box; /* include padding in element's width */
}

.header-left {
  display: flex;
  align-items: center;
}

.header-right {
  display: flex;
  align-items: center;
}

.header-link {
  margin-right: 20px;
  font-size: 20px;
  color: white;
  text-decoration: none;
  cursor: pointer;
}

.sign-in {
  margin-left: 10px;
}

.App-link {
  color: #FF9D00;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Button styles */
.button {
  display: inline-block;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
  border-radius: 25px;
  border: none;
  margin-right: 10px;
  cursor: pointer;
}

/* Apple green button */
.button-green {
  background-color: #4CD964;
  color: white;
}

/* Apple red button */
.button-red {
  background-color: #FF3B30;
  color: white;
}

/* Apple blue button */
.button-blue {
  background-color: #007AFF;
  color: white;
}

.sign-in-home-button {
  background: none;
  color: white;
  display: inline-block;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  border-radius: 25px;
  border: none;
  margin-right: 10px;
  cursor: pointer;
}

.begin-home-button {
  background-color: #4CD964;
  color: white;
  display: inline-block;
  padding: 10px 20px;
  font-size: 30px;
  font-weight: bold;
  text-align: center;
  border-radius: 10px;
  border: none;
  margin-right: 10px;
  cursor: pointer;
}

.home-create-a-crypto-title {
  text-align: center;
  font-size: 2em;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 80vh;
}

.home-page {
  background: linear-gradient(
                rgba(0, 0, 0, 0.1), /* Change the RGBA values for your desired tint color and opacity */
                rgba(0, 0, 0, 0.1)
              ),
              url('./assets/homeBackground2.png');
  background-repeat: no-repeat;
  background-size: 130%;
  background-position: center center;
  background-color: #222222;
  overflow: hidden;
  height: 100vh;
}

/* Smaller screens (e.g., mobile devices) */
@media (max-width: 900px) {
  .home-page {
    background-size: 180%;
  }
}

/* Medium screens (e.g., tablets) */
@media (min-width: 901px) and (max-width: 1200px) {
  .home-page {
    background-size: 170%;
  }
}

/* Larger screens (e.g., desktops) */
@media (min-width: 1200px) {
  .home-page {
    background-size: 140%;
  }
}

.App {
  height: 100%;
  overflow: hidden;
  }
  

.bottom-nav {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #282c34;
  color: white;
  text-align: center;
}
  
  .bottom-nav ul {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding: 10;
    margin: 0;
  }
  
  .bottom-nav li {
    list-style: none;
    margin: 0 10px;
  }
  
  .bottom-nav a {
    color: white;
    text-decoration: none;
    font-size: 16px;
    font-weight: bold;
  }

  .modal {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: black;
    border-radius: 20px;
  }

  .modal, .overlay {
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 901;
  }

  .centered-modal {
    position: fixed;
    top: 0;
    left: 0;
    margin: auto;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
  }
  

  .modal-content {
    background-color: black;
    border-radius: 20px;
    padding: 20px;
  }

  .crypto-modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: black;
    border-radius: 10px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    color: white;
    height: 70vh;
    padding: 20px;
    border-radius: 10px;
    z-index: 999;
    max-width: 600px;
    padding: 2rem;
    border-radius: 25px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
    overflow: auto;
  }

  @media screen and (max-width: 480px) {
    .crypto-modal {
      width: 100%;
    }
  }

  @media screen and (max-width: 780px) {
    .crypto-modal {
      width: 35%;
    }
  }

  .crypto-modal-content{
    display: fixed;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .crypto3-modal-header{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 5px;
    width: 304px;
    padding: 1px;
  }

  .crypto-modal-close-button {
    position: absolute;
    top: 15px;
    right: 15px;
    background-color: transparent;
    border: none;
    font-size: 24px;
    color: white;
    cursor: pointer;
  }

  .crypto-modal-back-button {
    position: absolute;
  top: 15px;
  left: 15px;
  background-color: transparent;
  border: none;
  font-size: 24px;
  color: white;
  cursor: pointer;
  }

  .crypto-modal-continue-button {
    background-color: white;
    color: #222222;
    display: inline-block;
    padding: 10px 40px;
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    border-radius: 7px;
    border: none;
    cursor: pointer;
  }

  .crypto-modal-white-button {
    background-color: white;
    color: #222222;
    display: inline-block;
    padding: 10px 20px;
    font-size: 30px;
    font-weight: bold;
    text-align: center;
    border-radius: 10px;
    border: none;
    cursor: pointer;
  }

  .crypto-modal-black-button {
    background-color: black;
    color: white;
    display: inline-block;
    padding: 10px 20px;
    font-size: 30px;
    font-weight: bold;
    text-align: center;
    border-radius: 10px;
    border: none;
    cursor: pointer;
  }
  
  .error-label {
    display: flex;
    color: #FF3B30;
    text-align: center;
    word-wrap: break-word;
    max-width: 260px;
    font-size: 17px;
    height: 60px
  }

  .bitcoin-price-button-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
    gap: 10px;
    
  }

  .bitcoin-price-button-row button {
    flex: 1;
    padding: 5px;
    background-color: #ffffff;
    color: #222222;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
  }

  .dark-gray-background {
    background-color: #222222;
  }

  .darker-gray-background {
    background-color: #1a1a1a;
  }

  .crypto-modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #222222;
    border-radius: 10px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    color: white;
    height: 60%;
    padding: 20px;
    border-radius: 10px;
    z-index: 999;
    max-width: 600px;
    padding: 2rem;
    border-radius: 25px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
    overflow: auto;
  }

  @media screen and (max-width: 480px) {
    .crypto-modal {
      width: 100%;
    }
  }

  @media screen and (max-width: 780px) {
    .crypto-modal {
      width: 35%;
    }
  }


  .crypto-modal-textfield {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 10px;
    width: 100%;
    background-color: #222222;
  }
  
  .crypto-modal-textfield > label {
    margin-bottom: 0px;
    font-size: 15px;
  }
  
  .crypto-modal-textfield > input {
    width: 300px;
    padding: 3px;
    font-size: 22px;
    margin-top: 4px;
    background-color: #222222;
    color: white;
    border: 1px solid lightgray;
    border-radius: 5px;
  }

  .browse-crypto-list-container {

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 20px;
    color: white;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 10px;
    padding-top: 10px;
    width: 100vw;
    border-bottom: 1px solid #444444;
  }

  .asset-crypto-list-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-left: 1px;
    margin-right: 20px;
    color: white;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    width: 81vw;
    border-bottom: 1px solid #444444;
  }

  .crypto-property {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 5px;
    font-size: 16px;
    gap: 0;
    text-align: center;
    flex-basis: calc(100% / 7); /* divide by number of elements to ensure equal width */
    max-width: 45px;
    max-height: 45px;
  }

  .crypto-property-assets-dashboard {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    gap: 0;
    text-align: center;
    flex-basis: calc(100% / 14); /* divide by number of elements to ensure equal width */
    width: 70px;
    height: 30px;
  }

  .crypto-property-favorite-dashboard {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 5px;
    font-size: 16px;
    gap: 0;
    text-align: center;
    flex-basis: calc(100% / 8); /* divide by number of elements to ensure equal width */
    max-width: 200px;
    max-height: 45px;
  }

  .crypto-property-img {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 5px 0;
    flex-basis: calc(100% / 7); /* divide by number of elements to ensure equal width */
    border-radius: 50%; /* make the element circular */
    max-width: 65px;
    max-height: 65px;
    width: 65px;
    height: 65px;
    overflow: hidden; /* hide any overflowing content */
  }

  .trade-timeframe-button-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
    gap: 10px;
  }

  .trade-timeframe-button-row button {
    flex: 1;
    padding: 5px;
    background-color: #222222;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 14px;
    cursor: pointer;
  }

  .trade-timeframe-button-selected-button {
    flex: 1;
    padding: 5px;
    background-color: white !important;
    color: black !important;
    border: none;
    border-radius: 4px;
    font-size: 16px;

  }

  .trade-buy-sell-button-row {
    display: flex;
    justify-content: space-evenly;
    flex: 1;
    padding: 10px 20px;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 16px;
  }

  .trade-buy-sell-button-row button {
    flex: 1;
    background-color: #222222;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 18px;
    cursor: pointer;
  }

  .trade-buy-sell-button-selected-button {
    flex: 1;
    background-color: white !important;;
    color: #222222 !important;
    border: none;
    border-radius: 4px !important;
  }

  .buy-sell-textfield {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    width: 100%;
    align-items: center;
  }
  
  .buy-sell-textfield > label {
    margin-bottom: 0px;
    margin-left: 40px;
    color: white;
    align-self: flex-start;
  }
  
  .buy-sell-textfield > input {
    width: 200px;
    padding: 3px;
    font-size: 17px;
    margin-top: 4px;
    background-color: #222222;
    color: white;
    border: 1px solid lightgray;
    border-radius: 8px;
  }

  .buy-sell-price-button-row {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px; /* reduce gap */
    margin-left: 10px;
    margin-right: 10px
  }
  
  .buy-sell-price-button-row button {
    flex: 1;
    padding: 5px;
    background-color: white;
    color: #222222;
    border: 1px solid white;
    border-radius: 8px;
    font-size: 12px; /* reduce font-size */
    font-weight: bold;
    cursor: pointer;
  }
  
  
  .buy-sell-set-order-button {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #FFA14A;
    color: #222222;
    padding: 10px 60px;
    font-size: 17px;
    font-weight: bold;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    margin: 0 auto;
    position: relative;
  }
  
  .about-crypto-icon-img {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 5px;
    max-width: 90px;
    max-height: 90px;
    width: 90px;
    height: 90px;
    border-radius: 50%; /* make the element circular */
    overflow: hidden; /* hide any overflowing content */
  }

  @keyframes floatingAnimation {
    0% {
      transform: translate(0, 0);
      opacity: 1;
    }
    100% {
      transform: translate(calc(100vw * var(--translate-x)), calc(100vh * var(--translate-y)));
      opacity: 0;
    }
  }
  
  .gray-circle {
    position: absolute;
    background-color: gray;
    border-radius: 50%;
    animation: floatingAnimation var(--duration, 10s) infinite linear;
  }

  .inverted-colors {
    filter: invert(1);
}

.stars-background {
  position: relative;
  width: 100vh;
  height: 100vh;
  overflow: hidden;
  background-color: #1a1a1a;
  z-index: 9999;
}

.star {
  /* ... other styles ... */
  transition: top 1s, left 1s;
}

  @keyframes spin {
    from { transform: rotate(0deg); }
    to { transform: rotate(360deg); }
  }

  .sign-in-page-textfield {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 10px;
    width: 100%;
    background-color: #1a1a1a;
  }
  
  .sign-in-page-textfield > label {
    margin-bottom: 0px;
    font-size: 25px;
  }
  
  .sign-in-page-textfield > input {
    width: 350px;
    padding: 10px;
    font-size: 25px;
    margin-top: 4px;
    background-color: #1a1a1a;
    color: white;
    border: 1px solid lightgray;
    border-radius: 10px;
  }

  .checkmark-animation {
    font-size: 50px; /* Adjust size as needed */
    color: #4CD964; /* Checkmark color */
    overflow: hidden;
    white-space: nowrap;
    width: 0;
    animation: revealCheckmark 1.5s forwards; /* Animation to reveal the checkmark */
  }
  
  @keyframes revealCheckmark {
    from {
      width: 0;
    }
    to {
      width: 50px; /* Adjust to the width of the checkmark */
    }
  }
  
